var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "tableModal",
      attrs: {
        title: _vm.modalTitle,
        "mask-closable": false,
        draggable: false,
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("Table", {
        ref: "table",
        staticStyle: { "margin-top": "10px" },
        attrs: {
          loading: _vm.loading,
          height: "385",
          border: "",
          columns: _vm.noInsProjectColumn,
          sortable: "custom",
          data: _vm.tableData,
        },
      }),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "default" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }